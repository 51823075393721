import {Box} from '@mantine/core';
import type {FC} from 'react';
import {routes} from '../../../config/constants';
import isAppWebView from '../../../config/isAppWebView';
import {useAuth} from '../../../core/usecases/auth/AuthContext';
import {convertNlToBr} from '../../services/convertNlToBr';
import {CommonLink} from '../CommonLink';
import {GeneralFooter} from '../Footer/general';
import {GeneralHeaderContainer} from '../Header/General/container';

export const ClientSideErrorPage: FC<{
  message: string;
  isShowGssktNextLink?: boolean;
}> = ({message, isShowGssktNextLink = false}) => {
  const {user} = useAuth();
  const hasGssktNextAccount = user?.isAgreedRule;

  return (
    <>
      <div>
        {!isAppWebView() && <GeneralHeaderContainer />}
        <main>
          <Box className="flex pc:min-h-[500px] min-h-[300px] items-center justify-center bg-background-1 py-12 pc:py-32">
            <div className="bg-white-1 flex flex-col items-center justify-center p-36 w-[90%] max-w-[600px] shadow-bottom">
              <p className="text-black-1 leading pc:text-center">
                {convertNlToBr(message)}
              </p>
              {isShowGssktNextLink && hasGssktNextAccount && (
                <p className="text-black-1 text-md pc:text-center mt-12">
                  社会人向けキャリア支援サービス 外資就活ネクストは
                  <CommonLink
                    href={routes.LIIGA}
                    className="text-link leading pc:hover:opacity-80"
                  >
                    こちら
                  </CommonLink>
                  <br />
                  ※外資就活のアドレス・PWでログインが可能です
                </p>
              )}
              <CommonLink
                href={routes.HOME}
                className="text-link text-md leading mt-12 pc:hover:opacity-80"
              >
                トップへ戻る
              </CommonLink>
            </div>
          </Box>
        </main>
        {!isAppWebView() && <GeneralFooter />}
      </div>
    </>
  );
};
